export const FirebaseConfig = {
	"projectId": "ut-limo-c2331",
	"appId": "1:729658226783:web:e6cfaf59707590775383ea",
	"databaseURL": "https://ut-limo-c2331-default-rtdb.firebaseio.com",
	"storageBucket": "ut-limo-c2331.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDpqmp8Mj3L6sfZo38uDP6Ay_CcU9_UCFI",
	"authDomain": "ut-limo-c2331.firebaseapp.com",
	"messagingSenderId": "729658226783",
	"measurementId": "G-9XECXG913Z"
};